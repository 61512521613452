import TextMarqueeComponent from '../components/TextMarquee/viewer/TextMarquee';


const TextMarquee = {
  component: TextMarqueeComponent
};


export const components = {
  ['TextMarquee']: TextMarquee
};

